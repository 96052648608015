import React from "react";
import Box from "@mui/material/Box";
import "../../style/base.css";
import WrapBoxTypo from "../BoxTypo";
import { StaticImage, GatsbyImage } from "gatsby-plugin-image";

const HarajukuSecondView = (props) => {
  return (
    <>
      <Box sx={{ display: ["none", "block"] }}>
        <Box sx={{ position: "relative" }}>
          <Box sx={{ width: ["100vw"] }}>
            <StaticImage
              alt="img"
              src="../../images/secondView/second-view-2.jpg"
              placeholder="blurred"
            />
          </Box>
          <Box
            sx={{
              position: "absolute",
              top: "0",
              height: "100%",
              width: "100%",
              maxWidth: "1100px",
              margin: "auto",
              right: "0",
              left: "0",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                height: "100%",
                width: "95%",
                maxWidth: "1100px",
                margin: "auto",
              }}
            >
              <Box sx={{ display: ["none", "none", "block"] }}>
                <Box textAlign="left" sx={{ whiteSpace: ["pre", "pre"] }}>
                  <WrapBoxTypo fs="14px" lh={["42px", "42px"]} color="#364540">
                    {props.text}
                  </WrapBoxTypo>
                </Box>
              </Box>
              <Box sx={{ display: ["none", "block", "none"] }}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    height: "100%",
                    width: "95%",
                    maxWidth: "1100px",
                    margin: "auto",
                  }}
                >
                  <Box textAlign="left" sx={{ whiteSpace: ["pre", "pre"] }}>
                    <WrapBoxTypo
                      fs="14px"
                      lh={["42px", "42px"]}
                      color="#364540"
                    >
                      {props.textTb}
                    </WrapBoxTypo>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box sx={{ display: ["block", "none"] }}>
        <Box sx={{ position: "relative" }}>
          <Box sx={{ width: ["100%"] }}>
            <StaticImage
              alt="img"
              src="../../images/secondView/second-view-2-sp.jpg"
              placeholder="blurred"
              style={{ maxHeight: "576px", objectFit: "cover" }}
            />
          </Box>
          <Box
            sx={{
              position: "absolute",
              top: "0",
              height: "100%",
              width: "100%",
              maxWidth: "1100px",
              margin: "auto",
              right: "0",
              left: "0",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <Box textAlign="center" sx={{ whiteSpace: ["pre", "pre"] }}>
                <WrapBoxTypo fs="14px" lh={["42px", "42px"]} color="#364540">
                  {props.textSp}
                </WrapBoxTypo>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default HarajukuSecondView;
