import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { StaticImage, GatsbyImage, getImage } from "gatsby-plugin-image";
import Head from "../components/Head";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Service from "../components/Service";
import Instagram from "../components/InstagramEmbeded";
import Recruit from "../components/Recruit";
import News from "../components/News";
import Menu from "../components/Menu";
import ShopTextSection from "../components/ShopTextSection";
import SecondView from "../components/SecondView";
import Map from "../components/Map";
import HarajukuSliderImage from "../components/HarajukuSliderImage";
import HarajukuSecondView from "../components/SecondView/HarajukuSecondView";
import AOS from "aos";

const Harajuku = () => {
  const data = useStaticQuery(
    graphql`
      query {
        drink_menu: file(relativePath: { eq: "menu/drink-menu-image.jpg" }) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    `
  );
  if (typeof document !== `undefined`) {
    AOS.init({ duration: 800, easing: "ease-in-sine", delay: 300 });
  }
  const drinkMenu = getImage(data.drink_menu);
  return (
    <>
      <Head
        title="フォルカフェ原宿店 | forucafe"
        description='フォルカフェ原宿店です。forucafe原宿店は、\"Iceberg(アイスバーグ)\" と呼ばれるガラス張りでその名の通り氷山のような、特徴的な建築物の1Fにあります。天井も高く、自然光もたっぷり差し込む開放感のある店内で思い思いの時間をお過ごしください。'
        url="https://forustyle-985bb.web.app/harajuku"
        imagePath="https://forustyle-985bb.web.app/ogp-harajuku.jpg"
      />
      <Header title="forucafe 原宿店" />
      <HarajukuSliderImage />
      <div data-aos="fade-in">
        <ShopTextSection
          bgcolor="#F3F5F5"
          text={`原宿の街と併設したオフィスで働く人の”パワースポット”を目指して`}
        />
      </div>
      <div data-aos="fade-in">
        <HarajukuSecondView
          text={`forucafe原宿店は、"Iceberg(アイスバーグ)" と呼ばれるガラス張りで
その名の通り氷山のような、特徴的な建築物の1Fにあります。
天井も高く、自然光もたっぷり差し込む開放感のある店内で
思い思いの時間をお過ごしください。`}
          textTb={`forucafe原宿店は、"Iceberg(アイスバーグ)" と呼ばれるガラス張りで
その名の通り氷山のような、特徴的な建築物の1Fにあります。
天井も高く、自然光もたっぷり差し込む開放感のある店内で
思い思いの時間をお過ごしください。`}
          textSp={`forucafe原宿店は、
"Iceberg(アイスバーグ)" 
と呼ばれるガラス張りで
その名の通り氷山のような、
特徴的な建築物の1Fにあります。
天井も高く、自然光もたっぷり差し込む
開放感のある店内で
思い思いの時間をお過ごしください。`}
          data={drinkMenu}
        />
      </div>
      <div data-aos="fade-in">
        <Menu shop="原宿店" isDetail={true} />
      </div>
      <div data-aos="fade-in">
        <Map
          title={`forucafe原宿店`}
          textUnderTheTitle={0}
          post={`〒150-0001
東京都渋谷区神宮前 6-12-18
WeWorkアイスバーグ1F
6-12-18,Jingumae,Shibuya-ku,Tokyo`}
          nearestStation={`明治神宮前駅7番出口より徒歩2分
JR山手線「原宿駅」徒歩5分`}
          phoneNumber={`080-9646-2341`}
          time={`9:00~19:00
(18:00お食事L.O.18:30ドリンクL.O.)`}
          isWifi={`有`}
          isPowerSupply={`無`}
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12965.703848016725!2d139.7041605!3d35.6665122!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xf156b42c87a537a3!2zZm9ydWNhZmUg5Y6f5a6_5bqXIEBXZVdvcmvjgqLjgqTjgrnjg5Djg7zjgrA!5e0!3m2!1sja!2sjp!4v1645788286080!5m2!1sja!2sjp"
        />
      </div>
      <div data-aos="fade-in">
        <News shop="原宿店" />
      </div>
      <div data-aos="fade-in">
        <Service />
      </div>
      <div data-aos="fade-in">
        <Recruit />
      </div>
      <div data-aos="fade-in">
        <Footer />
      </div>
    </>
  );
};

export default Harajuku;
